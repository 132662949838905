import React, { useState, useEffect } from 'react'

import {
  Container,
  ContainerHeader,
  HeaderTitle,
  Logo,
  ContainerLogo,
  Form,
  Check,
  ButtonContainer,
  StyledButton,
  SeeMore,
  ContainerSeeMore
} from './styles'

import { toast } from 'react-toastify'

import Combo from '../../components/CustomTextField'

import InputMask from 'react-input-mask'

import { MenuItem, FormControlLabel, CircularProgress } from '@material-ui/core'

import api from '../../services/api'

import { useLocation } from 'react-router-dom'

import KrotonLogo from '../../assets/logo-branco.svg'

import { Controller, useForm } from 'react-hook-form'

import states from '../../constants/state'

import history from '../../services/history'

import DateUtils from '../../util/DateUtils'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function Enrollment({ location }) {
  const { control, handleSubmit, errors, setValue } = useForm({
    mode: 'onSubmit'
  })

  const { executeRecaptcha } = useGoogleReCaptcha()

  const query = useQuery()

  const [mode, setMode] = useState({})
  const [shift, setShift] = useState({})
  const [unit, setUnit] = useState({})
  const [offer, setOffer] = useState({})

  const [loading, setLoading] = useState(false)

  const [modeOptions, setModeOptions] = useState([])
  const [shiftOptions, setShiftOptions] = useState([])
  const [unitOptions, setUnitOptions] = useState([])
  const [offerOptions, setOfferOptions] = useState([])

  const [onlineExam, setOnlineExam] = useState(true)
  const [enem, setEnem] = useState(false)
  const [specialNeeds, setSpecialNeeds] = useState(false)

  const [openAdditionalFields, setOpenAdditionalFields] = useState(false)

  async function findModes() {
    setLoading(true)
    try {
      const userId = query.get('id')
      const response = await api.get(
        `bot/modes/${userId}/${location.state.enrollment.courseId}`
      )
      setModeOptions(response.data.modes)
    } catch (e) {
      showError(
        'Não foi possível carregar as modalidades disponíveis! Tente mais tarde novamente'
      )
    }
    setLoading(false)
  }

  async function findShifts(modeId) {
    setLoading(true)
    try {
      const userId = query.get('id')
      const response = await api.get(
        `bot/shifts/${userId}/${location.state.enrollment.courseId}/${modeId}`
      )
      if (response.data.shifts) {
        setShiftOptions(response.data.shifts)
      }
    } catch (e) {
      showError(
        'Não foi possível carregar os turnos disponíveis! Tente mais tarde novamente'
      )
    }
    setLoading(false)
  }

  async function findUnits(shiftId) {
    setLoading(true)
    try {
      const userId = query.get('id')
      const response = await api.get(
        `bot/units/${userId}/${location.state.enrollment.courseId}/${mode.modeId}/${shiftId}`
      )
      if (response.data.units) {
        setUnitOptions(response.data.units)
      }
    } catch (e) {
      showError(
        'Não foi possível carregar as unidades disponíveis! Tente mais tarde novamente'
      )
    }
    setLoading(false)
  }

  // async function findOffers(unitId) {
  //   setLoading(true)
  //   try {
  //     const response = await api.get(
  //       `bot/offers/${location.state.enrollment.courseId}/${unitId}/${mode.modeId}/${shift.shiftId}`
  //     )
  //     if (response.data.offers) {
  //       setOfferOptions(response.data.offers)
  //     }
  //   } catch (e) {
  //     setOfferOptions([])
  //   }
  //   setLoading(false)
  // }

  function showError(message) {
    toast.error(message)
  }

  async function onSubmit(form) {
    setLoading(true)
    const { state } = location
    try {
      const token = await executeRecaptcha('send_form')
      const responseRecaptcha = await api.post('auth/recaptcha/validate', {
        token
      })
      if (!responseRecaptcha.data) {
        showError(
          'Não foi possível fazer sua inscrição! Tente mais tarde novamente'
        )
        setLoading(false)
        return
      }

      state.enrollment.modeId = mode.modeId
      state.enrollment.mode = mode.modeName

      state.enrollment.shiftId = shift.shiftId
      state.enrollment.shift = shift.shiftName

      state.enrollment.unitId = unit.unitId
      state.enrollment.unitName = unit.unitName
      state.enrollment.krotonId = unit.krotonId ? unit.krotonId : ''

      state.enrollment.enem = enem
      state.enrollment.onlineExam = onlineExam
      state.enrollment.specialNeeds = specialNeeds

      state.enrollment.offerId = unit.offerId
      state.enrollment.system = unit.offerSystem

      // if (!enem && !onlineExam) {
      //   if (form.date) {
      //     state.enrollment.offerName = form.date
      //     state.operation = 'SAVE'
      //   } else if (offer !== {}) {
      //     const { offerId, offerName, offerSystem, secondOfferId } = offer

      //     state.enrollment.offerId = offerId
      //     state.enrollment.offerName = offerName
      //     state.enrollment.system = offerSystem
      //     state.enrollment.nearestOfferId = secondOfferId
      //   }
      // } else {
      //   state.enrollment.offerId = unit.nearestOfferId
      //   state.enrollment.system = unit.nearestOfferSystem
      // }

      Object.keys(form).map((key) => {
        if (form[key]) {
          state.enrollment[key] = form[key]
        }
      })
      const response = await api.post('app/report', state)
      navigateToSuccess(response)
    } catch (e) {
      toast.error('Ocorreu um erro ao enviar a inscrição!')
    }
    setLoading(false)
  }

  function navigateToSuccess(response) {
    let payload = {}
    if (response.data.onlineExamURL) {
      payload.url = response.data.onlineExamURL
    }
    if (response.data.sent) {
      payload.message = 'Sua inscrição foi efetuada com sucesso!'
    } else {
      payload.message = response.data.message
    }
    history.push('/confirm', payload)
  }

  async function validatePage(id) {
    try {
      setLoading(true)
      await api.post(`users/${id}/validate`)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      history.push('/')
    }
  }

  useEffect(() => {
    const userId = query.get('id')
    if (location.state === undefined) {
      history.push(`/subscribe?id=${userId}`)
      return
    }
    if (userId) {
      validatePage(userId)
    } else {
      history.push('/')
    }
    findModes()
  }, [])

  return (
    <div>
      <Container>
        <ContainerHeader>
          <ContainerLogo>
            <Logo src={KrotonLogo} />
          </ContainerLogo>
          <HeaderTitle>
            Informe seus dados e curso de interesse para concluir sua inscrição
            gratuitamente
          </HeaderTitle>
        </ContainerHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/* <FormControlLabel
            label="Desejo utilizar a nota do ENEM"
            control={
              <Check
                checked={enem}
                onChange={() => {
                  setEnem(!enem)
                  setOnlineExam(false)
                }}
              />
            }
          ></FormControlLabel> */}
          <Combo
            select
            label="Modalidade"
            onChange={(event) => {
              let mode = event.target.value
              setMode(mode)
              setShiftOptions([])
              setShift({})
              setUnitOptions([])
              setUnit({})
              findShifts(mode.modeId)
            }}
          >
            {modeOptions.map((item) => (
              <MenuItem key={item.modeId} value={item}>
                {item.modeName}
              </MenuItem>
            ))}
          </Combo>
          <Combo
            margin="10"
            select
            label="Turno"
            disabled={shiftOptions.length === 0}
            onChange={(event) => {
              let shift = event.target.value
              setShift(shift)
              setUnitOptions([])
              setUnit({})
              findUnits(shift.shiftId)
            }}
          >
            {shiftOptions.map((item) => (
              <MenuItem key={item.shiftId} value={item}>
                {item.shiftName}
              </MenuItem>
            ))}
          </Combo>
          <Combo
            margin="10"
            select
            label="Unidade"
            disabled={unitOptions.length === 0}
            onChange={(event) => {
              let unit = event.target.value
              setUnit(unit)
              // findOffers(unit.unitId)
              // if (unit.onlineExam && !enem) {
              //   setOnlineExam(true)
              //   setEnem(false)
              // }
            }}
          >
            {unitOptions.map((item) => (
              <MenuItem key={item.unitId} value={item}>
                {item.unitName}
              </MenuItem>
            ))}
          </Combo>
          {/* {unit && !enem && unit.onlineExam && (
            <FormControlLabel
              style={{ marginTop: 10 }}
              label="Desejo fazer a prova online"
              control={
                <Check
                  checked={onlineExam}
                  onChange={() => {
                    setOnlineExam(!onlineExam)
                    setEnem(false)
                    setOffer({})
                  }}
                />
              }
            ></FormControlLabel>
          )}
          {!enem && !onlineExam && offerOptions.length !== 0 && (
            <Combo
              select
              margin="0"
              label="Data da Prova"
              disabled={unitOptions.length === 0}
              onChange={(event) => {
                let offer = event.target.value
                setOffer(offer)
              }}
            >
              {offerOptions.map((item) => (
                <MenuItem key={item.offerId} value={item}>
                  {item.offerName}
                </MenuItem>
              ))}
            </Combo>
          )}
          {!enem && !onlineExam && unit.unitId && offerOptions.length === 0 && (
            <Controller
              control={control}
              name="date"
              type="text"
              rules={{
                required: {
                  value:
                    !onlineExam && !enem && offerOptions.length === 0
                      ? true
                      : false,
                  message: 'É necessário preencher o campo de data da prova'
                },
                maxLength: {
                  value: 10,
                  message:
                    'O limite de caracteres permitido pra este campo é 10'
                },
                validate: {
                  isValid: (date) => {
                    if (date.length === 10) {
                      try {
                        const [day, month, year] = date.split('/')
                        let formattedDate = new Date(
                          Number.parseInt(year),
                          Number.parseInt(month) - 1,
                          Number.parseInt(day)
                        )
                        const monthNumber = Number.parseInt(month)
                        if (month > 12 || month < 1) {
                          return 'Digite uma data válida'
                        }
                        if (DateUtils.isPastDate(formattedDate)) {
                          return 'Digite uma data futura'
                        }
                      } catch (e) {
                        return 'Digite uma data válida'
                      }
                    }
                  }
                }
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <InputMask
                  mask="99/99/9999"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                >
                  {(inputProps) => (
                    <Combo
                      defaultValue=""
                      fullWidth
                      margin="10"
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                      error={errors.date}
                      helperText={errors.date ? errors.date.message : ''}
                      label="Data da prova"
                    />
                  )}
                </InputMask>
              )}
            />
          )} */}

          <FormControlLabel
            style={{ marginTop: 10 }}
            label="Possui necessidades especiais"
            control={
              <Check
                checked={specialNeeds}
                onChange={() => {
                  setSpecialNeeds(!specialNeeds)
                }}
              />
            }
          ></FormControlLabel>

          {!openAdditionalFields && (
            <ContainerSeeMore>
              <SeeMore
                onClick={() => {
                  setOpenAdditionalFields(true)
                }}
              >
                MAIS
              </SeeMore>
            </ContainerSeeMore>
          )}

          {openAdditionalFields && (
            <>
              <Controller
                control={control}
                name="postalCode"
                type="text"
                rules={{
                  maxLength: {
                    value: 9,
                    message:
                      'O limite de caracteres permitido pra este campo é 9'
                  }
                }}
                render={({ onChange, onBlur, value, name, ref }) => (
                  <InputMask
                    mask="99999-999"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  >
                    {(inputProps) => (
                      <Combo
                        onChange={onChange}
                        defaultValue=""
                        fullWidth
                        checked={value}
                        inputRef={ref}
                        error={errors.postalCode}
                        helperText={
                          errors.postalCode ? errors.postalCode.message : ''
                        }
                        label="CEP"
                      />
                    )}
                  </InputMask>
                )}
              />
              <Controller
                as={Combo}
                control={control}
                defaultValue=""
                fullWidth
                margin="10"
                name="state"
                type="text"
                rules={{
                  maxLength: {
                    value: 2,
                    message:
                      'O limite de caracteres permitido pra este campo é 2'
                  }
                }}
                select
                error={errors.state}
                helperText={errors.state ? errors.state.message : ''}
                label="Estado"
              >
                {states.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  )
                })}
              </Controller>
              <Controller
                as={Combo}
                defaultValue=""
                margin="10"
                fullWidth
                control={control}
                name="city"
                type="text"
                rules={{
                  maxLength: {
                    value: 80,
                    message:
                      'O limite de caracteres permitido pra este campo é 80'
                  }
                }}
                error={errors.city}
                helperText={errors.city ? errors.city.message : ''}
                label="Município"
              />
              <Controller
                as={Combo}
                defaultValue=""
                margin="10"
                fullWidth
                control={control}
                name="neighborhood"
                type="text"
                rules={{
                  maxLength: {
                    value: 80,
                    message:
                      'O limite de caracteres permitido pra este campo é 80'
                  }
                }}
                error={errors.neighborhood}
                helperText={
                  errors.neighborhood ? errors.neighborhood.message : ''
                }
                label="Bairro"
              />
              <Controller
                as={Combo}
                defaultValue=""
                fullWidth
                margin="10"
                control={control}
                name="address"
                type="text"
                rules={{
                  maxLength: {
                    value: 80,
                    message:
                      'O limite de caracteres permitido pra este campo é 80'
                  }
                }}
                error={errors.address}
                helperText={errors.address ? errors.address.message : ''}
                label="Endereço"
              />
              <Controller
                as={Combo}
                defaultValue=""
                fullWidth
                margin="10"
                control={control}
                name="number"
                type="number"
                error={errors.number}
                helperText={errors.number ? errors.number.message : ''}
                label="Número"
              />
              <Controller
                as={Combo}
                defaultValue=""
                fullWidth
                margin="10"
                control={control}
                name="complement"
                type="text"
                rules={{
                  maxLength: {
                    value: 80,
                    message:
                      'O limite de caracteres permitido pra este campo é 80'
                  }
                }}
                error={errors.complement}
                helperText={errors.complement ? errors.complement.message : ''}
                label="Complemento"
              />
              <Controller
                as={Combo}
                defaultValue=""
                margin="10"
                fullWidth
                control={control}
                name="rg"
                type="text"
                rules={{
                  maxLength: {
                    value: 15,
                    message:
                      'O limite de caracteres permitido pra este campo é 15'
                  },
                  minLength: {
                    value: 3,
                    message:
                      'O limite mínimo de caracteres permitido pra este campo é 3'
                  }
                }}
                error={errors.rg}
                helperText={errors.rg ? errors.rg.message : ''}
                label="RG"
              />
            </>
          )}
          <ButtonContainer>
            <StyledButton type="submit" disabled={loading}>
              {loading ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                'Confirmar'
              )}
            </StyledButton>
          </ButtonContainer>
        </Form>
      </Container>
    </div>
  )
}

export default Enrollment

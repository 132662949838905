import React, { useEffect, useState } from 'react'

import KrotonLogo from '../../assets/logo-branco.svg'

import history from '../../services/history'

import InputMask from 'react-input-mask'

import { useForm, Controller } from 'react-hook-form'

import { useLocation } from 'react-router-dom'

import TextField from '../../components/CustomTextField'

import api from '../../services/api'

import ValidationUtils from '../../util/ValidationUtils'

import { toast } from 'react-toastify'

import { MenuItem, CircularProgress } from '@material-ui/core'

import {
  Container,
  ContainerHeader,
  ContainerLogo,
  Logo,
  HeaderTitle,
  Form,
  ButtonContainer,
  StyledButton
} from './styles'
import DateUtils from '../../util/DateUtils'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function Save() {
  const { control, handleSubmit, errors, setValue } = useForm({
    mode: 'onSubmit'
  })
  const [courses, setCourses] = useState([])
  const [loading, setLoading] = useState(false)
  const [coursesNotFound, setCoursesNotFound] = useState(false)

  const query = useQuery()

  async function validatePage(id) {
    try {
      setLoading(true)
      await api.post(`users/${id}/validate`)

      try {
        const response = await api.get(`bot/courses/${id}`)
        setCourses(response.data.courses)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        setCoursesNotFound(true)
      }
    } catch (e) {
      setLoading(false)
      history.push('/')
    }
  }

  function clearFields() {
    const fields = ['birthday', 'phone', 'course', 'email', 'name']
    for (let field of fields) setValue(field, '')
  }

  function formatDate(date) {
    return date.replace('/', '').replace('/', '')
  }

  function formatPhone(phone) {
    return phone
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '')
  }

  function formatCpf(cpf) {
    return cpf.replace('-', '').replace('.', '').replace('.', '')
  }

  async function onSubmit(data) {
    setLoading(true)
    try {
      const dateFormatted = formatDate(data.birthday)
      const phoneFormatted = formatPhone(data.phone)
      const cpfFormatted = formatCpf(data.cpf)

      const userId = query.get('id')

      let payload
      if (coursesNotFound) {
        payload = {
          enrollment: {
            birthday: dateFormatted,
            cellphone: '55' + phoneFormatted,
            course: data.course,
            email: data.email,
            name: data.name,
            cpf: cpfFormatted
          },
          operation: 'SAVE',
          userId
        }
      } else {
        payload = {
          enrollment: {
            birthday: dateFormatted,
            cellphone: '55' + phoneFormatted,
            course: courses.find((item) => item.courseId === data.course)
              .courseName,
            courseId: data.course,
            email: data.email,
            name: data.name,
            cpf: cpfFormatted
          },
          operation: 'SAVE',
          userId
        }
      }
      await api.post('app/report', payload)
      payload.operation = 'ENROLL'
      clearFields()
      history.push(`/subscribe/enroll?id=${userId}`, payload)
    } catch (e) {
      toast.error(
        'Ocorreu um erro ao enviar a inscrição! Tente mais tarde novamente'
      )
    }
    setLoading(false)
  }

  useEffect(() => {
    const userId = query.get('id')
    if (userId) {
      validatePage(userId)
    } else {
      history.push('/')
    }
  }, [])

  return (
    <div>
      <Container>
        <ContainerHeader>
          <ContainerLogo>
            <Logo src={KrotonLogo} />
          </ContainerLogo>
          <HeaderTitle>
            Informe seus dados e curso de interesse para concluir sua inscrição gratuitamente
          </HeaderTitle>
        </ContainerHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            as={TextField}
            defaultValue=""
            fullWidth
            control={control}
            name="name"
            type="text"
            rules={{
              required: 'É necessário preencher o nome',
              maxLength: {
                value: 80,
                message: 'O limite de caracteres permitido pra este campo é 80'
              }
            }}
            error={errors.name}
            helperText={errors.name ? errors.name.message : ''}
            label="Nome"
          />
          <Controller
            control={control}
            name="phone"
            type="text"
            defaultValue=""
            rules={{
              required: 'É necessário preencher o telefone',
              validate: {
                isValid: (phone) => {
                  if (!ValidationUtils.isValidPhone(phone)) {
                    return 'Digite um telefone válido'
                  }
                }
              }
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <InputMask
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                mask="(99) 99999-9999"
              >
                {(inputProps) => (
                  <TextField
                    margin="10"
                    defaultValue=""
                    fullWidth
                    onChange={onChange}
                    checked={value}
                    inputRef={ref}
                    error={errors.phone}
                    helperText={errors.phone ? errors.phone.message : ''}
                    label="Telefone celular (ddd + número)"
                  />
                )}
              </InputMask>
            )}
          />
          <Controller
            as={TextField}
            defaultValue=""
            fullWidth
            control={control}
            name="email"
            type="text"
            margin="10"
            rules={{
              required: 'É necessário preencher o email',
              maxLength: {
                value: 80,
                message: 'O limite de caracteres permitido pra este campo é 80'
              },
              validate: {
                isValid: (email) => {
                  if (!ValidationUtils.isValidEmail(email)) {
                    return 'Digite um email válido'
                  }
                }
              }
            }}
            error={errors.email}
            helperText={errors.email ? errors.email.message : ''}
            label="E-mail"
          />
          <Controller
            control={control}
            name="birthday"
            type="text"
            defaultValue=""
            rules={{
              required: [true, 'É necessário preencher a data de nascimento'],
              maxLength: {
                value: 11,
                message: 'O limite de caracteres permitido pra este campo é 11'
              },
              validate: {
                isValid: (date) => {
                  if (date.length === 10) {
                    try {
                      const [day, month, year] = date.split('/')
                      let formattedDate = new Date(
                        Number.parseInt(year),
                        Number.parseInt(month) - 1,
                        Number.parseInt(day)
                        )
                      let nowYear = new Date().getFullYear()
                      let age = (nowYear - Number.parseInt(year))   
                      if (age < 15) {
                        return 'Data de nascimento inválida. Candidato deve possuir no mínimo 15 anos.';
                      }
                      if (DateUtils.isPastThanYear(formattedDate, 1900)) {
                        return 'O menor ano de nascimento aceitável é 1900'
                      }
                      if (DateUtils.isFutureDate(formattedDate)) {
                        return 'Digite uma data anterior ao dia de hoje'
                      }
                    } catch (e) {
                      return 'Digite uma data de nascimento válida'
                    }
                  }
                }
              }
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <InputMask
                mask="99/99/9999"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              >
                {(inputProps) => (
                  <TextField
                    margin="10"
                    defaultValue=""
                    fullWidth
                    onChange={onChange}
                    checked={value}
                    inputRef={ref}
                    error={errors.birthday}
                    helperText={errors.birthday ? errors.birthday.message : ''}
                    label="Data de nascimento"
                  />
                )}
              </InputMask>
            )}
          />
          <Controller
            control={control}
            name="cpf"
            type="text"
            defaultValue=""
            rules={{
              required: 'É necessário preencher o CPF',
              validate: {
                isValid: (cpf) => {
                  if (!ValidationUtils.isValidCpf(cpf)) {
                    return 'Digite um CPF válido'
                  }
                }
              }
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <InputMask
                mask="999.999.999-99"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              >
                {(inputProps) => (
                  <TextField
                    margin="10"
                    defaultValue=""
                    fullWidth
                    onChange={onChange}
                    checked={value}
                    inputRef={ref}
                    error={errors.cpf}
                    helperText={errors.cpf ? errors.cpf.message : ''}
                    label="CPF"
                  />
                )}
              </InputMask>
            )}
          />
          {coursesNotFound ? (
            <Controller
              as={TextField}
              defaultValue=""
              fullWidth
              margin="10"
              control={control}
              name="course"
              type="text"
              rules={{
                required: 'É necessário escolher o curso'
              }}
              error={errors.course}
              helperText={errors.course ? errors.course.message : ''}
              label="Curso"
            />
          ) : (
            <Controller
              as={TextField}
              defaultValue=""
              fullWidth
              margin="10"
              control={control}
              name="course"
              type="text"
              rules={{
                required: 'É necessário escolher o curso'
              }}
              error={errors.course}
              helperText={errors.course ? errors.course.message : ''}
              label="Curso"
              select
            >
              {courses.map((item) => {
                return (
                  <MenuItem key={item.courseId} value={item.courseId}>
                    {item.courseName}
                  </MenuItem>
                )
              })}
            </Controller>
          )}
          <ButtonContainer>
            <StyledButton type="submit" disabled={loading}>
              {loading ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                'Confirmar'
              )}
            </StyledButton>
          </ButtonContainer>
        </Form>
      </Container>
    </div>
  )
}

export default Save

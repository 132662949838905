import styled from 'styled-components'
import media from 'styled-media-query'
import { Paper } from '@material-ui/core'

export const Container = styled.div``

export const CustomPaper = styled(Paper)`
  width: 100%;
  border-radius: 8px;
  padding: 40px;
`

export const HeaderTitle = styled.h1`
  font-size: 34px;
  color: #494948;

  ${media.lessThan('medium')`
    font-size: 28px;
  `}
`

export const HeaderSubtitle = styled.p`
  font-size: 22px;
  margin-top: 8px;
  padding: 5px;
  overflow-wrap: break-word;

  text-align: start;
  ${media.lessThan('medium')`
    font-size: 18px;
    max-width: 300px;
  `};
`

export const ContainerLogo = styled.div`
  background-color: #0564AB;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 15px;
`

export const Logo = styled.img`
  height: 50px;
  width: 270px;

  ${media.lessThan('medium')`
    width: 200px;
  `}
`

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import GlobalStyles from './styles/GlobalStyles'

import ENV from './config/Env'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={ENV.RECAPTCHA_KEY}>
      <App />
      <GlobalStyles />
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

import React from 'react'

import * as S from './styles'
import KrotonLogo from '../../assets/logo-branco.svg'

import { Grid, Slide } from '@material-ui/core'

function Success({ location }) {
  return (
    <S.Container>
      <Slide in={true} direction="right">
        <S.CustomPaper style={{ backgroundColor: '#F7F7F7' }} elevation={4}>
          <Grid container style={{ display: 'flex' }}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <S.ContainerLogo>
                <S.Logo src={KrotonLogo} />
              </S.ContainerLogo>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <S.HeaderTitle>
                Parabéns, sua inscrição foi realizada com sucesso!
              </S.HeaderTitle>

              <S.HeaderSubtitle>
                Em breve você será contactado para concluir o processo.
              </S.HeaderSubtitle>

              {location.state.url && (
                <S.HeaderSubtitle>
                  <strong>URL da inscrição de prova online: </strong>
                  <a href={location.state.url}>{location.state.url}</a>
                </S.HeaderSubtitle>
              )}
            </Grid>
          </Grid>
        </S.CustomPaper>
      </Slide>
    </S.Container>
  )
}

export default Success

import styled from 'styled-components'
import media from 'styled-media-query'

import { Paper, TextField, Checkbox, Button } from '@material-ui/core'

export const Container = styled(Paper)`
  width: 100%;
  border-radius: 8px;
  padding: 40px;
  background-color: #f7f7f7 !important;
`

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 65%;

  ${media.lessThan('medium')`
    max-width: 100%;
  `}
`

export const HeaderTitle = styled.h1`
  font-size: 34px;
  color: #494948;

  ${media.lessThan('medium')`
    font-size: 22px;
  `}
`

export const ContainerLogo = styled.div`
  background-color: #0564AB;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 15px;
  max-width: 290px;
`

export const Logo = styled.img`
  height: 50px;
  width: 270px;

  ${media.lessThan('medium')`
    width: 200px;
  `}
`

export const Form = styled.form`
  max-width: 65%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  ${media.lessThan('medium')`
    max-width: 100%;
  `}
`

export const Combo = styled(TextField)`
  margin-top: ${(props) => props.margin}px !important;
`

export const Check = styled(Checkbox)`
  font-size: 10px;
  color: #0564AB !important;
  margin-top: ${(props) => props.margin}px !important;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: flex-end;
`

export const StyledButton = styled(Button)`
  background-color: #0564AB !important;
  color: #fff !important;
  padding: 10px;
  height: 50px;
  width: 200px;

  ${media.lessThan('medium')`
   width: 100% !important;
  `}
`

export const SeeMore = styled.span`
  font-size: 14px;
  color: #0564AB;
  cursor: pointer;
  font-weight: bold;
`

export const ContainerSeeMore = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`

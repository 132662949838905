import React from 'react'

import * as S from './styles'
import KrotonLogo from '../../assets/logo-branco.svg'

import { Grid, Slide } from '@material-ui/core'

function InvalidLink() {
  return (
    <S.Container>
      <Slide in={true} direction="right">
        <S.CustomPaper style={{ backgroundColor: '#F7F7F7' }} elevation={4}>
          <Grid container style={{ display: 'flex' }}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <S.ContainerLogo>
                <S.Logo src={KrotonLogo} />
              </S.ContainerLogo>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <S.HeaderTitle style={{ fontSize: 34 }}>
                Link inválido
              </S.HeaderTitle>
              <S.HeaderSubtitle>
                Você tentou acessar um link de formulário inválido.
              </S.HeaderSubtitle>
            </Grid>
          </Grid>
        </S.CustomPaper>
      </Slide>
    </S.Container>
  )
}

export default InvalidLink

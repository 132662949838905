import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  html, body {
    height: 100%;
    background-color: #0564AB;
  }

  *, button, input {
    border: 0 !important;
    font-family: 'Roboto', sans-serif;
    transition: color .2s ease-out;
  }

  ul {
    list-style: none;
  }
`

import 'react-toastify/dist/ReactToastify.css'
import MainLayout from './layouts/Main'

import Routes from './routes/index'

import { ToastContainer } from 'react-toastify'

function App() {
  return (
    <MainLayout>
      <Routes />
      <ToastContainer />
    </MainLayout>
  )
}

export default App

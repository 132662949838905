const PROD = {
  BASE_URL: 'https://api.captarexpress.com.br/prod/k360live',
  RECAPTCHA_SECRET_KEY: '6Ldzfd8ZAAAAAL34eJgY3vkVnp6yEjqpnREFZvvP',
  RECAPTCHA_KEY: '6Ldzfd8ZAAAAAFEQaiZPr3jBiYnqqyuKDe0n9nyS',
  X_ACCESS_TOKEN: '4e82abb4-2718-4d65-bcd4-c4e147c3404f'
}

const DEV = {
  BASE_URL: 'https://api-hml.captarexpress.com.br/hml/k360live',
  RECAPTCHA_SECRET_KEY: '6Ldzfd8ZAAAAAL34eJgY3vkVnp6yEjqpnREFZvvP',
  RECAPTCHA_KEY: '6Ldzfd8ZAAAAAFEQaiZPr3jBiYnqqyuKDe0n9nyS',
  X_ACCESS_TOKEN: '4e82abb4-2718-4d65-bcd4-c4e147c3404f'
}

export default process.env.REACT_APP_ENV === 'production' ? PROD : DEV

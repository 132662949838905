import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'

import history from '../services/history'

import Save from '../pages/Save'
import SecondForm from '../pages/Enrollment'
import Success from '../pages/Success'
import InvalidLink from '../pages/InvalidLink'

function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <Route component={Success} exact path="/confirm" />
        <Route component={Save} exact path="/subscribe" />
        <Route component={SecondForm} exact path="/subscribe/enroll" />
        <Route component={InvalidLink} exact path="/" />
      </Switch>
    </Router>
  )
}

export default Routes

import validator from 'email-validator'

function formatCpf(cpf) {
  return cpf.replace('-', '').replace('.', '').replace('.', '')
}

const ValidationUtils = {
  isValidPhone(phone) {
    if (phone.replace('_', '').length === 15) {
      return true
    }
    return false
  },
  isValidEmail(email) {
    return validator.validate(email)
  },
  isValidBirthday(birthday) {
    if (birthday.replace('_', '').length === 10) {
      const str = birthday.substr(6)
      try {
        const year = Number.parseInt(str)
        if (year >= 1940) {
          return true
        }
      } catch (e) {
        return false
      }
    }
  },
  isValidCpf(cpf) {
    let cpfWithoutPoints = cpf.replace('_', '')
    if (cpfWithoutPoints.length === 14) {
      let cpfFormatted = formatCpf(cpfWithoutPoints)
      if (this.isCorrectFormatCpf(cpfFormatted)) {
        return true
      }
    }
    return false
  },
  isCorrectFormatCpf(strCPF) {
    let Soma
    let Resto
    Soma = 0
    let i
    if (strCPF == '00000000000') return false

    for (i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
    Resto = (Soma * 10) % 11

    if (Resto == 10 || Resto == 11) Resto = 0
    if (Resto != parseInt(strCPF.substring(9, 10))) return false

    Soma = 0
    for (i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
    Resto = (Soma * 10) % 11

    if (Resto == 10 || Resto == 11) Resto = 0
    if (Resto != parseInt(strCPF.substring(10, 11))) return false
    return true
  }
}

export default ValidationUtils

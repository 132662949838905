import { isAfter, isBefore, isValid } from 'date-fns'

const DateUtils = {
  toTimezone(date) {
    const [day, month, year] = date.split('/')
    console.log(day, month, year)
  },
  isPastDate(date) {
    return isBefore(date, new Date())
  },
  isFutureDate(date) {
    return isAfter(date, new Date())
  },
  isPastThanYear(date, year) {
    return isBefore(date, new Date(`01-01-${year}`))
  },
  isValid(date) {
    return isValid(date)
  }
}

export default DateUtils

import axios from 'axios'
import ENV from '../config/Env'

const api = axios.create({
  baseURL: ENV.BASE_URL,
  headers: {
    'x-access-token': ENV.X_ACCESS_TOKEN
  }
})

export default api
